var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"order-items-panel"}},[_c('panel',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Return Requests ")]},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.returns,"single-expand":"","expanded":_vm.expanded,"item-key":"id","show-expand":"","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.statusColorMap[item.status]}},[_vm._v(_vm._s(_vm.statusMap[item.status]))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding-left":"0","padding-right":"0"},attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"rounded-0",staticStyle:{"background-color":"#1976d2","color":"white"},attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"color":"white"}},[_vm._v("Product ID")]),_c('th',{staticClass:"text-left",staticStyle:{"color":"white"}},[_vm._v("Name")]),_c('th',{staticClass:"text-left",staticStyle:{"color":"white"}},[_vm._v("Qty")])])]),_c('tbody',_vm._l((item.products),function(product,i){return _c('tr',{key:i,staticStyle:{"pointer-events":"none"}},[_c('td',[_vm._v(_vm._s(product.product_id))]),_c('td',[_vm._v(_vm._s(_vm.$utils.productName(product.product)))]),_c('td',[_vm._v(_vm._s(product.quantity))])])}),0)]},proxy:true}],null,true)})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status === 'PENDING')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteReturn(item.id)}}},[_vm._v("mdi-delete")]):_vm._e()]}}])})]},proxy:true}])}),_c('confirmation-modal',{ref:"confirm"}),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }